












































import { Vue, Component } from "vue-property-decorator";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import Moment from "moment";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import QRCode from "qrcode";
import { decryptHash, getUserEvents } from "@/model/utils";

interface DataEntry {
  label: string;
  data: { x: Moment.Moment; y: number }[];
  backgroundColor: any;
  borderColor: any;
  tension: number;
  pointRadius: number;
}

function createGradient(context: any) {
  const {
    chart: { ctx, chartArea },
  } = context;
  if (!chartArea) return;
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top
  );
  gradient.addColorStop(0.4, "#3498db");
  gradient.addColorStop(0.6, "#2ecc71");
  gradient.addColorStop(0.95, "#e74c3c");
  return gradient;
}

@Component
export default class QRApp extends Vue {
  id = "";
  org = "";
  hash = "";
  lastScanned = "";
  avgTemp = "0.0";

  $refs!: {
    qrImage: HTMLCanvasElement;
    eventStats: HTMLCanvasElement;
    qrContainer: HTMLElement;
  };
  async getQRCode(): Promise<string> {
    const queryCode =
      this.$route.params.id ?? this.$router.currentRoute.query["code"];
    const storedHash = window.localStorage.getItem("qrHash");
    const id = window.localStorage.getItem("qrCode");

    if (storedHash === queryCode) {
      const qrid = window.localStorage.getItem("qrCode") ?? "";
      this.id = id?.split("-")[1] ?? qrid;
      return qrid;
    } else {
      const code = await decryptHash(queryCode.replace("tko-", ""));
      if (code !== "Invalid") {
        const [org, id] = code.split("-");
        window.localStorage.setItem("qrHash", queryCode);
        window.localStorage.setItem("qrCode", code);
        this.id = id;
        this.org = org;
        this.hash = queryCode;
        return code;
      } else {
        return "Invalid";
      }
    }
  }

  parseUserEvents(
    events: { date: Date; reading: number }[]
  ): [DataEntry, DataEntry] {
    const datesToTemp: {
      [key: string]: {
        high: { reading: number; date: Date };
        low: { reading: number; date: Date };
      };
    } = {};
    events.forEach(({ date, reading }) => {
      const eventDate = date.toDateString();
      // remove seconds from date
      if (eventDate in datesToTemp) {
        const currTemps = datesToTemp[eventDate];
        if (currTemps.high.reading < reading) {
          currTemps.high = { reading, date };
        } else if (currTemps.low.reading > reading) {
          currTemps.low = { reading, date };
        }
      } else {
        const newDateReading = { reading, date };
        datesToTemp[eventDate] = {
          high: newDateReading,
          low: newDateReading,
        };
      }
    });
    const highData = Object.entries(datesToTemp).map(([_date, { high }]) => ({
      x: Moment(high.date),
      y: high.reading,
    }));
    const lowData = Object.entries(datesToTemp).map(([_date, { low }]) => ({
      x: Moment(low.date),
      y: low.reading,
    }));
    const pointRadius = 5;
    return [
      {
        label: "high",
        data: highData,
        backgroundColor: createGradient,
        borderColor: createGradient,
        tension: 0.4,
        pointRadius,
      },
      {
        label: "low",
        data: lowData,
        backgroundColor: createGradient,
        borderColor: createGradient,
        tension: 0.4,
        pointRadius,
      },
    ];
  }
  async mounted() {
    const qrCode = await this.getQRCode();
    if (qrCode !== "Invalid") {
      // Get QR Code for image
      const { clientWidth, clientHeight } = this.$refs.qrContainer;
      const width = Math.min(clientWidth, clientHeight) * 0.85;
      QRCode.toCanvas(this.$refs.qrImage, "tko-" + qrCode, {
        margin: 0,
        width,
      });
      const queryCode =
        this.$route.params.id ?? this.$router.currentRoute.query["code"];
      const ctx = this.$refs.eventStats.getContext("2d");
      //Get user events from API
      const events = await getUserEvents(queryCode.replace("tko-", ""));
      const parsedEvents = this.parseUserEvents(events);
      if (events.length > 0) {
        if (ctx !== null) {
          const lastestDate = events.sort(
            (a, b) => (a.date as any) - (b.date as any)
          )[events.length - 1].date;

          this.lastScanned =
            Moment.parseZone(lastestDate).format("h:mm DD/MM/YY");
          const avgTemp =
            events.reduce((prev, { reading }) => prev + Number(reading), 0) /
            events.length;
          const stdDeviation = Math.sqrt(
            events.reduce(
              (prev, { reading }) => prev + Math.pow(reading - avgTemp, 2),
              0
            ) / events.length
          );
          const normalTemp = events.length > 10 ? avgTemp + stdDeviation : 37.0;
          const maxTemp = normalTemp + 0.5;
          this.avgTemp = normalTemp.toFixed(1);

          // create line area chart
          Chart.register(zoomPlugin);
          Chart.register(annotationPlugin);
          new Chart(ctx, {
            type: "line",
            data: {
              datasets: parsedEvents,
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "week",
                    displayFormats: {
                      week: "DD/MM",
                    },
                  },
                  ticks: {
                    // Edit: added this to avoid overlapping - thanks for comment
                    minRotation: 0, // <-- just try any number
                    maxRotation: 90, // <-- just try any number
                  },
                },
                y: {
                  suggestedMin: 33,
                  suggestedMax: 38,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                annotation: {
                  annotations: {
                    feverLine: {
                      // Indicates the type of annotation
                      type: "line",
                      label: {
                        content: `Fever: ${maxTemp.toFixed(1)}`,
                        backgroundColor: "rgba(231, 76, 60,0.7)",
                        yAdjust: -13,
                        enabled: true,
                      },
                      yMin: maxTemp,
                      yMax: maxTemp,
                      borderColor: "#e74c3c",
                      borderDash: [5, 5],
                    },
                    normalLine: {
                      // Indicates the type of annotation
                      type: "line",
                      label: {
                        content: `Normal: ${normalTemp.toFixed(1)}`,
                        backgroundColor: "rgba(46, 204, 113,0.7)",
                        yAdjust: 13,
                        enabled: false,
                      },
                      yMin: normalTemp,
                      yMax: normalTemp,
                      borderDash: [5, 5],
                      borderColor: "#2ecc71",
                    },
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "x",
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    drag: {
                      enabled: true,
                    },
                    mode: "x",
                  },
                },
              },
            },
          });
        }
      } else {
        this.lastScanned = "Never";
      }
    }
  }
}
