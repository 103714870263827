var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" Devices Overview "),_c('div',[(_vm.isSuperAdmin)?_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mr-4",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Device "),_c('v-icon',{staticClass:"ml-1 mb-1"},[_vm._v(" mdi-plus ")])],1)],1)]}}],null,false,3590141851),model:{value:(_vm.newItemDialog),callback:function ($$v) {_vm.newItemDialog=$$v},expression:"newItemDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Create New Device")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3","md":"6"}},[_c('v-text-field',{attrs:{"label":"Device Id"},model:{value:(_vm.newDeviceId),callback:function ($$v) {_vm.newDeviceId=$$v},expression:"newDeviceId"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"6"}},[_c('v-text-field',{attrs:{"label":"Label Name"},model:{value:(_vm.newDeviceLabel),callback:function ($$v) {_vm.newDeviceLabel=$$v},expression:"newDeviceLabel"}})],1)],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.adminUsers,"label":"users","item-text":"email","item-value":"email","multiple":"","chips":"","light":"","max-height":"400"},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1):_vm._e(),(_vm.isSuperAdmin)?_c('v-menu',{staticClass:"ml-8",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"color":"grey lighten-5"}},'v-btn',attrs,false),on),[_vm._v(" Action "),_c('v-icon',{staticClass:"mb-1"},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,4254230640)},[_c('v-list',[(_vm.isSuperAdmin)?_c('v-dialog',{attrs:{"max-width":"310px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Delete ")])]}}],null,false,1462620016),model:{value:(_vm.deleteDevicesDialog),callback:function ($$v) {_vm.deleteDevicesDialog=$$v},expression:"deleteDevicesDialog"}},[_c('v-card',{staticClass:"flex row justify-center"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Delete Selected Devices")])]),_c('v-card-text',[_vm._v(" You will delete the device for all users removing any saved names. This does not delete events so you can add the device later. ")]),_c('v-card-actions',{},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDevicesDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteSelectedDevices}},[_vm._v(" Delete Devices ")])],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)]),_c('v-data-table',{attrs:{"items":_vm.devices,"headers":_vm.headers,"items-per-page":20,"hide-default-footer":"","show-select":_vm.isSuperAdmin,"max-height":"400","fixed-header":"","dense":""},scopedSlots:_vm._u([(_vm.isSuperAdmin)?{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.label,"large":""},on:{"save":function($event){return _vm.deviceChangeLabel(item, item.label)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":item.label,"rules":[_vm.validate],"single-line":"","clearable":"","counter":""},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})]},proxy:true}],null,true)},[_c('v-row',[_vm._v(" "+_vm._s(item.label)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1)],1)]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name,"large":""},on:{"save":function($event){return _vm.deviceChangeName(item, item.name)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":item.name,"rules":[_vm.validate],"single-line":"","clearable":"","counter":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_c('v-row',[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1)],1)]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"270px","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-cog")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Device Settings")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.label))])]),_c('v-container',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-checkbox',{attrs:{"label":"Notifications"},on:{"click":function($event){return _vm.updateDeviceAlerts(item.id, item.alerts)}},model:{value:(item.alerts),callback:function ($$v) {_vm.$set(item, "alerts", $$v)},expression:"item.alerts"}}),_c('v-checkbox',{attrs:{"label":"QR Mode"},on:{"click":function($event){return _vm.updateDeviceQR(item.id, item.qr)}},model:{value:(item.qr),callback:function ($$v) {_vm.$set(item, "qr", $$v)},expression:"item.qr"}}),(_vm.isSuperAdmin)?_c('v-checkbox',{attrs:{"label":"Record Activity"},on:{"click":function($event){return _vm.updateDeviceRecord(item.id, item.record)}},model:{value:(item.record),callback:function ($$v) {_vm.$set(item, "record", $$v)},expression:"item.record"}}):_vm._e()],1)],1)],1)],1)]}},{key:"item.adminUsers",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-overflow":"","absolute":"","min-width":"400px","max-height":"400px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"icon":"","disabled":_vm.adminUsers.length === 0}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-format-list-bulleted-square ")])],1)]}}],null,true)},_vm._l((_vm.organizations),function(org){return _c('v-container',{key:org,staticClass:"list-row-org ma-0 px-0 pb-0 pt-2 white flex-nowrap"},[_c('v-row',{staticClass:"flex-nowrap px-8"},[_c('v-icon',{attrs:{"color":_vm.iconOrg(org, item.id) !== 'mdi-checkbox-blank-outline'
                ? 'blue darken-2'
                : ''},on:{"click":function($event){return _vm.toggleDeviceForOrg(org, item.id)}}},[_vm._v(" "+_vm._s(_vm.iconOrg(org, item.id))+" ")]),_c('v-list-group',{staticClass:"pr-3 list-item-org",attrs:{"ripple":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{attrs:{"min-width":"100%"}},[_vm._v(_vm._s(org))])]},proxy:true}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto"},_vm._l((_vm.adminUsers.filter(
                  function (val) { return val.organization === org; }
                )),function(user){return _c('v-list-item',{key:user.username},[_c('v-checkbox',{attrs:{"input-value":_vm.userHasDevice(user.devices, item.id)},on:{"click":function($event){return _vm.toggleDeviceForAdmin(item, user)}}}),_c('v-list-item-title',[_vm._v(_vm._s(user.email))])],1)}),1)],1)],1),_c('v-divider')],1)}),1)]}}],null,true),model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }