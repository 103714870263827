var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.isSuperAdmin)?_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" Admins Overview ")]):_vm._e(),(_vm.isSuperAdmin)?_c('v-data-table',{attrs:{"items":_vm.adminUsers,"headers":_vm.adminHeaders,"dense":""},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.organizations},on:{"change":function (input) { return _vm.changeOrg(input, item.username); }},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-edit-dialog',{attrs:{"large":"","return-value":item.organization},on:{"save":function($event){return _vm.addNewOrg(item)},"close":function($event){return _vm.closeNewOrg()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.validateOrg],"single-line":"","clearable":"","counter":""},model:{value:(_vm.tempOrg),callback:function ($$v) {_vm.tempOrg=$$v},expression:"tempOrg"}})]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"block":"","large":"","text":"","color":"blue","light":""}},[_vm._v(" Add Organization "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true}],null,true),model:{value:(item.organization),callback:function ($$v) {_vm.$set(item, "organization", $$v)},expression:"item.organization"}})]}}],null,true)}):_vm._e(),_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" Users Overview "),_c('div',[_c('v-btn',{staticClass:"my-2 mr-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.addNewUser}},[_vm._v(" New User "),_c('v-icon',{staticClass:"ml-1 mb-1"},[_vm._v(" mdi-plus ")])],1),_c('v-menu',{staticClass:"ml-8",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"color":"grey lighten-5"}},'v-btn',attrs,false),on),[_vm._v(" Action "),_c('v-icon',{staticClass:"mb-1"},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-dialog',{attrs:{"max-width":"310px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mr-4",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Delete ")])]}}]),model:{value:(_vm.deleteUsersDialog),callback:function ($$v) {_vm.deleteUsersDialog=$$v},expression:"deleteUsersDialog"}},[_c('v-card',{staticClass:"flex row justify-center"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Delete Selected Users")])]),_c('v-card-text',[_vm._v(" You will delete the User for all admins removing any saved names. This does not delete events so you can add the user later. ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteSelectedUsers}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteUsersDialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)]),_c('v-data-table',{attrs:{"items":_vm.qrUsers,"headers":_vm.qrHeaders,"items-per-page":20,"item-key":"qrid","show-select":"","dense":""},scopedSlots:_vm._u([{key:"item.qrid",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{ref:item.qrid ? item.qrid : 'tempEdit',attrs:{"return-value":item.qrid},on:{"close":function($event){return _vm.saveUserId(item.qrid, item)},"update:returnValue":function($event){return _vm.$set(item, "qrid", $event)},"update:return-value":function($event){return _vm.$set(item, "qrid", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('v-text-field',{attrs:{"label":item.qrid.id,"rules":[_vm.validateQR],"single-line":"","clearable":"","counter":""},model:{value:(_vm.tempId),callback:function ($$v) {_vm.tempId=$$v},expression:"tempId"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"small":""},on:{"click":_vm.randomId}},[_vm._v("Random")])],1)]},proxy:true}],null,true)},[_c('v-row',[_vm._v(" "+_vm._s(item.qrid)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1)],1)]}},{key:"item.qr",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"160","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-qrcode")])],1)]}}],null,true)},[_c('QRImage',{attrs:{"id":item.id()}})],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.printQR(item.id())}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),(item.qrid)?_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chart-timeline-variant")])],1)]}}],null,true)},[_c('QRUserStats',{attrs:{"qrid":item}})],1):_vm._e(),_c('MailForm',{attrs:{"user":item,"makeUserLink":_vm.makeUserLink}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.copyIdApp(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy QR App Link")])])]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('canvas',{ref:"qrImage",attrs:{"hidden":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }