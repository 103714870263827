



















































































































































































































import { Component, Vue } from "vue-property-decorator";
import DownloadCsv from "download-csv";
import { DataTableHeader } from "vuetify";

import UserInfo from "@/model/user-info";
import ScreeningChart from "@/components/ScreeningChart.component.vue";
import devicesOverview from "@/components/DevicesOverview.component.vue";
import usersOverview from "@/components/UsersOverview.component.vue";
import QRUserStats from "@/components/QRUserStats.component.vue";
import { formatDate } from "@/model/utils";
import { Device, EventTableItem, Admin, User, user } from "@/model/db-handler";
import Auth from "@/model/auth";
import { MIN_ERROR_THRESHOLD } from "@/constants";

const today = new Date();
const todayDate = `${today.getFullYear()}-${
  today.getMonth() + 1
}-${today.getDate()}`;

interface SavedSettings {
  devices: string[];
  timespan: { start: number } | [string, string];
}

@Component({
  components: {
    apexchart: ScreeningChart,
    usersOverview,
    devicesOverview,
    QRUserStats,
  },
})
export default class Home extends Vue {
  devices: Record<string, Device> = {};
  qrUsers: User[] = [];
  selectedDevices: string[] = [];
  eventItems: EventTableItem[] = [];
  showDateRangePicker = false;
  isSuperAdmin = UserInfo.state.cognitoCredentials?.isSuperAdmin ?? false;
  adminUsers: Admin[] = [];
  dataIsLoading = false;
  showUsersOverview = false;
  showDevicesOverview = false;
  toggleTimespan = 0;
  showFilteredEvents = false;
  filterEvents: EventTableItem[] = [];

  timespans: [
    { value: { start: number } },
    { value: { start: number } },
    { value: { start: number } },
    { value: [string, string] }
  ] = [
    {
      value: { start: -1 }, // Relative hours to now.
    },
    {
      value: { start: -today.getHours() },
    },
    {
      value: { start: -(24 * 7) },
    },
    {
      value: [todayDate, todayDate], // Concrete date ranges
    },
  ];

  selectedTimespan: { start: number } | [string, string] =
    this.timespans[1].value;

  // noinspection JSMismatchedCollectionQueryUpdate
  headers: DataTableHeader[] = [
    {
      text: "Device",
      value: "device",
      width: 240,
    },
    {
      text: "Screened Temp C",
      value: "displayedTemperature",
      width: 140,
    },
    {
      text: "Fever Threshold C",
      value: "threshold",
      width: 120,
    },
    {
      text: "Time",
      value: "time",
      width: 240,
    },
  ];

  get auth() {
    return Auth.auth;
  }

  get dbHandler() {
    return UserInfo.state.databaseHandler!;
  }

  get hasQRID(): boolean {
    return true;
  }

  get selectedAllDevices() {
    return this.selectedDevices.length === this.deviceIds.length;
  }

  get icon() {
    if (this.selectedAllDevices) return "mdi-close-box";
    if (this.selectedDevices.length > 0 && !this.selectedAllDevices)
      return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  get deviceIds(): { name: string; id: string }[] {
    return Object.values(this.devices).filter(({ disable }) => !disable);
  }

  get dateRangeForSelectedTimespan(): {
    startDate: string;
    endDate?: string;
  } {
    if (!Array.isArray(this.selectedTimespan) && this.selectedTimespan.start) {
      const range = this.selectedTimespan;
      // we have a relative range.
      const startDate = formatDate(
        new Date(new Date().getTime() + 1000 * 60 * 60 * range.start)
      );
      return { startDate };
    } else {
      const customRange = this.timespans[this.timespans.length - 1]
        .value as string[];
      let [start, end] = customRange;
      if (end < start) {
        // Make sure end is always greater or equal than start.
        start = end;
        end = start;
      }

      // Add timezone offsets for NZ
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      const startDate = formatDate(new Date(Date.parse(start) + offset));
      const endDate = formatDate(
        new Date(Date.parse(end) + 1000 * 60 * 60 * 24 + offset)
      );
      return { startDate, endDate };
    }
  }

  get events(): EventTableItem[] {
    return this.eventItems.map((eventItem: EventTableItem) => ({
      ...eventItem,
      device: this.devices[eventItem.device].name,
    }));
  }

  get isCustomTimespan(): boolean {
    return Array.isArray(this.selectedTimespan);
  }

  get resultsSummaryText(): string {
    return `${this.eventItems.length} total screenings, ${
      this.eventItems.filter((item) => item.result === "Fever").length
    } screened as Fever`;
  }

  get numNormalEvents(): number {
    return this.eventItems.filter((item) => item.result === "Normal").length;
  }

  get numErrorEvents(): number {
    return this.eventItems.filter((item) => item.result === "Error").length;
  }

  get numFeverEvents(): number {
    return this.eventItems.filter((item) => item.result === "Fever").length;
  }

  get temperatures() {
    return [
      {
        name: "temperatures",
        data: this.eventItems.map(
          ({ displayedTemperature }) => displayedTemperature
        ),
      },
    ];
  }

  get userEmail(): string {
    return (
      this.auth.getCachedSession().getIdToken().decodePayload() as {
        email: string;
      }
    ).email;
  }

  cancelCustomTime() {
    this.showDateRangePicker = false;
  }

  saveCustomTime() {
    this.selectedTimespanChanged(this.selectedTimespan as string[]);
    this.showDateRangePicker = false;
  }

  sortItems(
    items: EventTableItem[],
    index: (string | undefined)[],
    isDesc: (boolean | undefined)[]
  ): EventTableItem[] {
    if (index[0] !== undefined) {
      const i = index[0] === "time" ? "timestamp" : index[0];
      if (isDesc[0]) {
        items.sort((a: any, b: any) => (a[i] < b[i] ? 1 : -1));
      } else {
        items.sort((a: any, b: any) => (a[i] > b[i] ? 1 : -1));
      }
    }
    return items;
  }

  deleteDevice(device: string) {
    this.dbHandler.deleteDevice(device);
    Vue.delete(this.devices, device);
    this.selectedDevicesChanged;
  }

  exportCsv() {
    let start = this.dateRangeForSelectedTimespan.startDate as string;
    start = start.substr(0, start.lastIndexOf("_"));
    let end =
      this.dateRangeForSelectedTimespan.endDate || formatDate(new Date());
    end = end.substr(0, end.lastIndexOf("_"));
    const range = `${start} - ${end}`.replace(/T/g, " ").replace(/_/g, "-");
    DownloadCsv(
      this.events.map((val) => ({ ...val, qrid: val.qrid.qrid })),
      {
        device: "Device",
        timestamp: "Date/Time",
        displayedTemperature: "Screened Temp C",
        threshold: "Fever Threshold C",
        time: "Time",
        result: "Screening Result",
      },
      `${this.selectedDevices
        .map((device) => this.devices[device].name.replace(/,/g, ""))
        .join("|")} -- ${range}.csv`
    );
  }

  getColorForItem(item: EventTableItem): string {
    if (item.displayedTemperature > MIN_ERROR_THRESHOLD) {
      return "#B8860B";
    }
    if (item.displayedTemperature > item.threshold) {
      return "#a81c11";
    }
    return "#11a84c";
  }

  async mounted(): Promise<void> {
    const configRaw = window.localStorage.getItem("config");
    if (configRaw !== null) {
      try {
        const config = JSON.parse(configRaw) as SavedSettings;
        this.selectedDevices = config.devices;
        if (Array.isArray(config.timespan)) {
          // Custom timespan:
          this.timespans[this.timespans.length - 1].value = config.timespan;
          this.selectedTimespan =
            this.timespans[this.timespans.length - 1].value;
        } else {
          const timespan = this.timespans.find(
            (timespan) =>
              !Array.isArray(timespan.value) &&
              (timespan as { value: { start: number } }).value.start ===
                (config.timespan as { start: number }).start
          );
          if (timespan) {
            this.selectedTimespan = timespan.value;
          }
        }
      } catch (e) {
        // Do nothing
      }
    }
    this.devices = await this.dbHandler.getDevices();
    if (this.isSuperAdmin) {
      this.adminUsers = await this.dbHandler.getAdminUsers();
    }
    const currAdmin = await this.dbHandler.getCurrAdmin();
    if (currAdmin && currAdmin.organization) {
      this.qrUsers = await this.dbHandler.getQRUsers(currAdmin.organization);
    }

    this.selectedDevices = this.selectedDevices.filter((device) =>
      Object.keys(this.devices).includes(device)
    );
    if (this.selectedDevices.length) {
      await this.fetchEventsForDevices(
        this.selectedDevices,
        this.dateRangeForSelectedTimespan
      );
      setInterval(async () => {
        if (this.shouldUpdate()) {
          const startDate = this.eventItems[0].tsc ?? new Date().toString();
          await this.fetchEventsForDevices(
            this.selectedDevices,
            { startDate },
            true
          );
        }
      }, 5000);
    }
  }

  shouldUpdate(): boolean {
    if (this.dateRangeForSelectedTimespan.endDate === undefined) return true;
    const endDate = Date.parse(this.dateRangeForSelectedTimespan.endDate);
    return endDate < today.getMilliseconds();
  }

  async qrKey(): Promise<string | undefined> {
    const currAdmin = await this.dbHandler.getCurrAdmin();
    const key = currAdmin?.organization ?? currAdmin?.username;
    return key;
  }

  async updateQRUser(qrid: string) {
    const key = (await this.qrKey()) ?? "";
    const newUser = user(key, qrid);
    if (key) {
      this.dbHandler.updateQRUser(newUser);
      if (!this.qrUsers.includes(newUser)) {
        this.qrUsers.push(newUser);
      }
    }
  }

  async deleteQRUsers(users: User[]) {
    users.forEach((user) => this.dbHandler.deleteQRUser(user));
    this.qrUsers = this.qrUsers.filter((user) => !users.includes(user));
  }

  async fetchEventsForDevices(
    devices: string[],
    timeFrame: { startDate: string; endDate?: string },
    isUpdate = false
  ): Promise<void> {
    this.dataIsLoading = !isUpdate;
    let { startDate, endDate } = timeFrame;
    const allEvents = await Promise.all(
      devices.map((device) =>
        this.dbHandler.getDeviceEvents(device, { startDate, endDate })
      )
    );
    const newEvents = allEvents
      .flat()
      .filter(
        (item: EventTableItem) => item.displayedTemperature > 33 || item.qrid
      )
      .sort((a: EventTableItem, b: EventTableItem) =>
        a.timestamp < b.timestamp ? 1 : -1
      );
    if (isUpdate) {
      const updatedEvents = newEvents.filter(
        (newEvent) =>
          !this.eventItems.some(
            (prevEvent) =>
              newEvent.displayedTemperature ===
                prevEvent.displayedTemperature &&
              newEvent.time === prevEvent.time
          )
      );
      this.eventItems = [...updatedEvents, ...this.eventItems];
    } else {
      this.eventItems = newEvents;
    }
    const hasQR = this.eventItems.find((event) => event.qrid);
    if (hasQR && this.headers[1].value !== "qrid") {
      this.headers.splice(1, 0, { text: "ID", value: "qrid", width: 100 });
    } else if (!hasQR && this.headers[1].value === "qrid") {
      this.headers.splice(1, 1);
    }
    this.dataIsLoading = false;
  }

  selectAllDevices(): void {
    if (this.deviceIds.length === this.selectedDevices.length) {
      this.selectedDevices = [];
      this.selectedDevicesChanged([]);
    } else {
      const deviceIds = this.deviceIds.map((val) => val.id);
      this.selectedDevices = deviceIds;
      this.selectedDevicesChanged(deviceIds);
    }
  }

  selectedDevicesChanged(deviceIds: string[]): void {
    window.localStorage.setItem(
      "config",
      JSON.stringify({
        devices: deviceIds,
        timespan: this.selectedTimespan,
      })
    );
    this.fetchEventsForDevices(deviceIds, this.dateRangeForSelectedTimespan);
  }

  selectedTimespanChanged(
    timespan:
      | {
          startDate: Date | number;
          endDate: Date | number | undefined;
        }
      | string[]
  ): void {
    window.localStorage.setItem(
      "config",
      JSON.stringify({
        devices: this.selectedDevices,
        timespan: this.isCustomTimespan
          ? this.timespans[this.timespans.length - 1].value
          : timespan,
      })
    );

    if (Array.isArray(timespan) && !this.showDateRangePicker) {
      this.showDateRangePicker = true;
    } else if (this.selectedDevices.length) {
      // Fetch events again with new timespan
      this.fetchEventsForDevices(
        this.selectedDevices,
        this.dateRangeForSelectedTimespan
      );
    }
  }

  filterFeverEvents(): void {
    this.showFilteredEvents = !this.showFilteredEvents;
    this.filterEvents = this.showFilteredEvents
      ? this.events.filter((item) => item.result === "Fever")
      : this.events;
  }

  signOut(): void {
    UserInfo.setLoggedOut();
    Auth.logout();
  }
}
