
































import Vue, { PropType } from "vue";
import { User } from "@/model/db-handler";

export default Vue.extend({
  props: {
    user: Object as PropType<User>,
    makeUserLink: Function as PropType<(user: User) => Promise<string>>,
  },
  data: function () {
    return {
      mail: "",
    };
  },
  methods: {
    closeMenu() {
      (this.$refs[`menu-${this.user.qrid}`] as any).isActive = false;
      this.mail = "";
    },
    async sendEmail(event: Event) {
      event.preventDefault();
      const link = await this.makeUserLink(this.user);
      const message = `mailto:${this.mail}?subject=Te%20Kahu%20Ora%20QR%20code&body=Kia%20ora%2C%0D%0A%0D%0A%0D%0AHere%20is%20a%20link%20to%20your%20Te%20Kahu%20Ora%20QR%20code%3A%0D%0A${link}%0D%0A%0D%0AYou%20can%20store%20this%20on%20your%20phone%20and%20show%20it%20after%20you%20have%20had%20your%0D%0Atemperature%20taken%20by%20a%20Te%20Kahu%20Ora%20camera%20as%20demonstrated%20on%20this%20video%3A%0D%0Ahttps%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D077GlZoMgqgInstructions%3A%0D%0A%0D%0AAndroid%0D%0AUsing%20chrome%20open%20the%20website%20on%20your%20phone%2C%20press%20%E2%80%9CAdd%20tko%20to%20Home%20screen%E2%80%9D%20in%20either%20the%20bottom%20of%20the%20screen%20or%20in%20the%20menu%2C%20and%20click%20install.%20The%20app%20will%20be%20ready%20for%20you%20to%20open%20when%20you%20take%20your%20temperature%20by%20simply%20holding%20up%20the%20QR%20code.%0D%0A%0D%0AiPhone%0D%0AUsing%20safari%20open%20the%20website%20on%20your%20phone%2C%20open%20the%20menu%20to%20press%20%E2%80%9CAdd%20to%20Home%20Screen%E2%80%9D.%20The%20app%20will%20be%20ready%20for%20you%20to%20open%20when%20you%20take%20your%20temperature%20by%20simply%20holding%20up%20the%20QR%20code.%0D%0A%0D%0A%0D%0AFurther%20Instructions%3A%20https%3A%2F%2Fdocs.google.com%2Fdocument%2Fd%2F1TQeq3cS_K6MI7G-pH2kwsNtgSfEBAuET7yHVC8FhQR4%2Fedit%23bookmark%3Did.6abgjbcsvrrx%0D%0A%0D%0A%0D%0ANg%C4%81%20mihi%20nui%2C%0D%0ATe%20Kahu%20Ora%20team`;
      window.open(message, "_blank");
      (this.$refs[`menu-${this.user.qrid}`] as any).isActive = false;
      this.mail = "";
    },
  },
});
