









import Vue from "vue";
import QRCode from "qrcode";
export default Vue.extend({
  props: {
    id: String,
  },
  mounted: async function () {
    QRCode.toCanvas(this.$refs.qrImage, this.id);
  },
});
