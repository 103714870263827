import Vue from "vue";
import Router, { NavigationGuard } from "vue-router";
import Home from "@/Home.vue";
import QRApp from "@/QRApp.vue";
import UserInfo from "@/model/user-info";
import Auth from "@/model/auth";

Vue.use(Router);
const requireAuth: NavigationGuard = (_to, _from, next) => {
  if (!Auth.auth.isUserSignedIn()) {
    UserInfo.setLoggedOut();
    if (window.location.href.includes("?code=")) {
      Auth.auth.parseCognitoWebResponse(window.location.href);
    } else {
      Auth.auth.onFailure = (err) => {
        console.log(err);
        Auth.auth.clearCachedTokensScopes();
        window.location.reload();
      };
      Auth.auth.getSession();
    }
  } else {
    Auth.setupUser();
    next();
  }
};

export default new Router({
  mode: "history",
  base: "/",
  routes: [
    { path: "/", name: "Home", component: Home, beforeEnter: requireAuth },
    { path: "/qr/:id", name: "Tekahuora QR", component: QRApp },
    { path: "/qr", name: "Tekahuora", component: QRApp },
  ],
});
