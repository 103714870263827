

















































































































































































































































































import Vue, { PropType } from "vue";
import { Device, Admin } from "@/model/db-handler";

export default Vue.extend({
  props: {
    isSuperAdmin: Boolean,
    devices: Array as PropType<Device[]>,
    adminUsers: Array as PropType<Admin[]>,
    deleteDevice: Function as PropType<(device: string) => Promise<void>>,
    updateDeviceName: Function as PropType<
      (device: string, newName: string) => Promise<string>
    >,
    updateDeviceLabel: Function as PropType<
      (device: string, newLabel: string) => Promise<string>
    >,
    updateDeviceAlerts: Function as PropType<
      (device: string, alerts: boolean) => Promise<void>
    >,
    updateDeviceQR: Function as PropType<
      (device: string, qrMode: boolean) => Promise<void>
    >,
    updateDeviceRecord: Function as PropType<
      (device: string, record: boolean) => Promise<void>
    >,
    toggleDeviceForAdmin: Function as PropType<
      (device: Device, user: Admin) => Promise<void>
    >,
  },
  data() {
    return {
      newItemDialog: false,
      deleteDevicesDialog: false,
      usersDialog: false,
      selectedUsers: [],
      selectedDevices: [] as Device[],
      newDeviceId: "",
      newDeviceLabel: "",
    };
  },
  computed: {
    headers() {
      const headers = [
        { text: "Label Id", value: "label", width: 165 },
        { text: "Name", value: "name", width: 165 },
        { text: "Version", value: "version", width: 165 },
        { text: "Settings", value: "settings", align: "center", width: 125 },
      ];

      if (this.isSuperAdmin) {
        const [salt, admins] = [
          { text: "Salt Id", value: "id", width: 105 },
          { text: "Admins", value: "adminUsers", align: "center" },
        ];
        return [salt, ...headers, admins];
      }
      return headers;
    },
    organizations: {
      get: function (): string[] {
        return [
          ...new Set(
            this.adminUsers
              .map((user) => user.organization ?? "")
              .filter((val) => val)
          ),
        ];
      },
    },
  },
  methods: {
    iconOrg(org: string, device: string) {
      const orgUsers = this.adminUsers.filter(
        (val) => val.organization === org
      );
      const usersWithDevice = orgUsers.filter((user) => this.userHasDevice(user.devices, device));
      if (usersWithDevice.length === orgUsers.length) {
        return "mdi-checkbox-marked";
      } else if (usersWithDevice.length === 0) {
        return "mdi-checkbox-blank-outline";
      } else {
        return "mdi-minus-box";
      }
    },
    validate(input: string): boolean | string {
      if (input) {
        return input.length < 20 ? true : "Max 20 Characters";
      }
      return true;
    },
    userHasDevice(
      userDevices: Record<string, Device>,
      deviceId: string
    ): boolean {
      const userDevice = Object.values(userDevices).find((device) => {
        return device.id === deviceId;
      });
      return userDevice ? !userDevice.disable : false;
    },
    deviceChangeLabel(device: Device, newLabel: string) {
      const label = newLabel ? newLabel : device.id;
      this.updateDeviceLabel(device.id, label);
      device.label = label;
      return label;
    },
    deviceChangeName(device: Device, newName: string) {
      const name = newName ? newName : device.label;
      this.updateDeviceName(device.id, name);
      device.name = name;
      return name;
    },
    deleteSelectedDevices() {
      this.selectedDevices.forEach((device) => {
        this.deleteDevice(device.id);
      });
      this.selectedDevices = [];
      this.deleteDevicesDialog = false;
    },
    toggleDeviceForOrg(org: string, deviceId: string) {
      const device = this.devices.find((val) => val.id === deviceId);
      if (device) {
        const orgUsers = this.adminUsers.filter(
          (val) => val.organization === org
        );
        const usersWithoutDevice = orgUsers.filter(
          (user) => !this.userHasDevice(user.devices, deviceId)
        );
        if (usersWithoutDevice.length !== orgUsers.length && usersWithoutDevice.length !== 0) {
          usersWithoutDevice.forEach((user) => {
            const userDevice = user.devices[deviceId]
            this.toggleDeviceForAdmin(device, user);
            if (userDevice) {
              userDevice.disable = false;
            }
          });
        } else {
          orgUsers.forEach(async (user) => {
            const userDevice = user.devices[deviceId]
            this.toggleDeviceForAdmin(device, user);
            if (userDevice) {
              userDevice.disable = true;
            }
          });
        }
      }
    },
    save() {
      const device: Device = {
        id: this.newDeviceId,
        label: this.newDeviceLabel,
        name: this.newDeviceLabel,
        record: false,
        alerts: false,
        qr: false,
        disable: false,
      };

      this.selectedUsers.forEach((user) => {
        const currUser = this.adminUsers.find(({ email }) => user === email);
        if (currUser) {
          this.toggleDeviceForAdmin(device, currUser);
        }
      });

      this.selectedUsers = [];
      this.newDeviceId = "";
      this.newDeviceLabel = "";
      this.newItemDialog = false;
    },
    close() {
      this.selectedUsers = [];
      this.newDeviceId = "";
      this.newDeviceLabel = "";
      this.newItemDialog = false;
    },
  },
});
