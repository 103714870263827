


































































































































































import Vue, { PropType } from "vue";
import { Admin, User, user } from "@/model/db-handler";
import { createHash } from "@/model/utils";
import QRImage from "@/components/QRImage.component.vue";
import QRUserStats from "@/components/QRUserStats.component.vue";
import MailForm from "@/components/MailForm.component.vue";
import QRCode from "qrcode";
import printJS from "print-js";
import { v4 } from "uuid";

export default Vue.extend({
  props: {
    isSuperAdmin: Boolean,
    adminUsers: Array as PropType<Admin[]>,
    users: Array as PropType<User[]>,
    qrKey: Function as PropType<() => Promise<string>>,
    updateQRUser: Function as PropType<(qrid: string) => void>,
    deleteQRUsers: Function as PropType<(users: User[]) => void>,
    changeUserEvents: Function as PropType<
      (userId: string, newId: string) => void
    >,
    updateAdminOrg: Function as PropType<
      (adminId: string, organization: string) => void
    >,
  },
  components: {
    QRImage,
    QRUserStats,
    MailForm,
  },
  data: function () {
    return {
      newUserDialog: false,
      deleteUsersDialog: false,
      tempUsers: [] as User[],
      selectedUsers: [] as User[],
      qrHeaders: [
        { text: "User ID", value: "qrid", width: "300px" },
        { text: "", value: "qr" },
      ],
      adminHeaders: [
        { text: "Email", value: "email" },
        { text: "Organization", value: "organization", width: "200px" },
      ],
      tempId: "",
      tempOrg: "",
      test: false,
    };
  },
  computed: {
    qrUsers: {
      get: function (): User[] {
        return [...this.users, ...this.tempUsers];
      },
    },
    organizations: {
      get: function (): string[] {
        return this.adminUsers
          .map((user) => user.organization ?? "")
          .filter((val) => val);
      },
    },
  },
  methods: {
    addNewUser() {
      this.tempUsers.push(user("", ""));
      this.$nextTick(() => {
        (this.$refs.tempEdit as any).isActive = true;
      });
    },
    validateQR(input: string): boolean | string {
      if (input) {
        const duplicate =
          this.qrUsers.filter(({ qrid }) => qrid === input).length !== 0;
        const long = input.length > 50;
        const hasDash = input.includes("-");
        return !long && !duplicate && !hasDash
          ? true
          : long
          ? "Max Characters 50"
          : hasDash
          ? "Can not contain '-'"
          : "Duplicate ID found";
      }
      return true;
    },
    sendEmail(event: Event) {
      event.preventDefault();
    },
    validateOrg(input: string): boolean | string {
      if (input) {
        const duplicate =
          this.organizations.filter((id) => id === input).length !== 0;
        const long = input.length > 50;
        return !long && !duplicate
          ? true
          : long
          ? "Max Characters 50"
          : "Duplicate Name found";
      }
      return true;
    },
    saveUserId(userId: string, existingUser = undefined as User | undefined) {
      if (this.tempId) {
        userId = this.tempId;
      } else {
        this.tempUsers = [];
        if (!userId) {
          return;
        }
      }
      if (
        this.qrUsers.find(({ qrid }) => userId === qrid || this.tempId === qrid)
      ) {
        this.tempUsers = [];
        return;
      } else if (existingUser?.qrid) {
        const newId = `tko-${existingUser.organization}-${userId}`;
        this.changeUserEvents(existingUser.id(), newId);
        this.deleteQRUsers([existingUser]);
        existingUser.qrid = userId;
      }
      this.tempUsers = [];
      this.updateQRUser(userId);
      this.tempId = "";
    },
    async getQRImage(id: string) {
      const qrimg = await QRCode.toDataURL(
        this.$refs.qrImage as HTMLCanvasElement,
        `tko-${id}`
      );
      return qrimg;
    },
    async printQR(id: string) {
      const qrimg = await this.getQRImage(id);
      printJS({ printable: qrimg, type: "image", imageStyle: "width:350px" });
    },
    closeNewOrg() {
      this.tempOrg = "";
    },
    addNewOrg(admin: Admin) {
      admin.organization = this.tempOrg;
      this.changeOrg(this.tempOrg, admin.username);
    },
    changeOrg(org: string, username: string) {
      this.updateAdminOrg(username, org);
    },
    deleteSelectedUsers() {
      this.deleteQRUsers(this.selectedUsers);
      this.selectedUsers = [];
      this.deleteUsersDialog = false;
    },
    randomId() {
      this.tempId = v4().replaceAll("-", "");
    },
    async makeUserLink(user: User) {
      const hashAPI = await createHash(`${user.organization}-${user.qrid}`);
      return `${window.location.toString()}qr/tko-${hashAPI}`;
    },
    async copyIdApp(id: User) {
      const link = await this.makeUserLink(id);
      navigator.clipboard.writeText(link);
    },
  },
});
